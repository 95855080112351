import { SVGProps } from 'react';

function ArrowDownIcon({
  path,
  width,
  height,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="currentColor"
      xmlns="//www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 13.447l5.42-5.9c.444-.475 1.2-.51 1.689-.078.488.43.524 1.165.08 1.64l-6.304 6.845a1.22 1.22 0 01-1.77 0L2.811 9.107a1.137 1.137 0 01.08-1.64 1.221 1.221 0 011.69.079l5.419 5.9z"
        fill="evenodd"
      />
    </svg>
  );
}

ArrowDownIcon.defaultProps = {
  width: 20,
  hight: 21,
};

export default ArrowDownIcon;
