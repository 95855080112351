import { SVGProps } from 'react';
import { PathDesktop, PathMobile, SVG } from './styles';

function EmailIcon(props: Omit<SVGProps<SVGSVGElement>, 'css'>): JSX.Element {
  return (
    <SVG xmlns="http://www.w3.org/2000/svg" {...props}>
      <PathMobile
        fill="currentColor"
        d="M19.8 0H2.2C0.99 0 0.011 0.95625 0.011 2.125L0 14.875C0 16.0438 0.99 17 2.2 17H19.8C21.01 17 22 16.0438 22 14.875V2.125C22 0.95625 21.01 0 19.8 0ZM19.8 4.25L11 9.5625L2.2 4.25V2.125L11 7.4375L19.8 2.125V4.25Z"
      />
      <PathDesktop
        fill="currentColor"
        d="M27.1543 0H3.1543C1.5043 0 0.169297 1.30398 0.169297 2.89773L0.154297 20.2841C0.154297 21.8778 1.5043 23.1818 3.1543 23.1818H27.1543C28.8043 23.1818 30.1543 21.8778 30.1543 20.2841V2.89773C30.1543 1.30398 28.8043 0 27.1543 0ZM27.1543 5.79545L15.1543 13.0398L3.1543 5.79545V2.89773L15.1543 10.142L27.1543 2.89773V5.79545Z"
      />
    </SVG>
  );
}

EmailIcon.defaultProps = {
  width: 24,
};

export default EmailIcon;
