import { SVGProps } from 'react';

function PlayOutlineIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M31.06 16c0 8.287-6.695 15-14.947 15-8.252 0-14.947-6.713-14.947-15 0-8.287 6.695-15 14.947-15C24.365 1 31.06 7.713 31.06 16z"
        stroke="#F3F4F8"
        strokeWidth={2}
      />
      <path d="M23.09 16l-10.465 6.062V9.938L23.09 16z" fill="#F3F4F8" />
    </svg>
  );
}

export default PlayOutlineIcon;
