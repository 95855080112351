import { SVGProps } from 'react';

export default function CommentsIcon(
  props: SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6137_121201)">
        <path d="M50 33.3332V43.7498C50 45.4074 49.3415 46.9971 48.1694 48.1693C46.9973 49.3414 45.4076 49.9998 43.75 49.9998H33.3333C30.4106 49.9968 27.5401 49.2253 25.0097 47.7627C22.4793 46.3 20.378 44.1976 18.9167 41.6665C20.4872 41.6553 22.0524 41.4821 23.5875 41.1498C24.7566 42.6116 26.2396 43.7916 27.9266 44.6023C29.6137 45.413 31.4616 45.8337 33.3333 45.8332H43.75C44.3025 45.8332 44.8324 45.6137 45.2231 45.223C45.6138 44.8323 45.8333 44.3024 45.8333 43.7498V33.3332C45.8328 31.4607 45.4109 29.6124 44.5987 27.9253C43.7865 26.2382 42.605 24.7556 41.1417 23.5873C41.4768 22.0526 41.6527 20.4874 41.6667 18.9165C44.1978 20.3779 46.3002 22.4791 47.7628 25.0095C49.2255 27.5399 49.997 30.4105 50 33.3332ZM37.4521 20.1061C37.6469 17.422 37.2617 14.7274 36.3226 12.2054C35.3835 9.68348 33.9125 7.39321 32.0095 5.49029C30.1066 3.58736 27.8164 2.11636 25.2944 1.17724C22.7724 0.238125 20.0778 -0.147099 17.3937 0.0477463C12.6373 0.590938 8.24359 2.85436 5.03995 6.41179C1.83631 9.96923 0.0438066 14.5752 0 19.3623L0 29.8623C0 35.1373 3.13958 37.4998 6.25 37.4998H18.125C22.9142 37.4586 27.5229 35.6673 31.0829 32.4635C34.6428 29.2596 36.9082 24.8645 37.4521 20.1061V20.1061ZM29.0625 8.43941C30.5415 9.92171 31.6849 11.7044 32.4153 13.6669C33.1456 15.6294 33.4459 17.7258 33.2958 19.8144C32.8512 23.5304 31.0635 26.9557 28.2694 29.4455C25.4753 31.9354 21.8675 33.318 18.125 33.3332H6.25C4.31667 33.3332 4.16667 30.6769 4.16667 29.8623V19.3623C4.18404 15.6214 5.56802 12.0159 8.05816 9.22416C10.5483 6.43244 13.9729 4.64705 17.6875 4.204C18.0333 4.179 18.3792 4.1665 18.725 4.1665C20.6443 4.16471 22.5452 4.54116 24.3189 5.27432C26.0927 6.00749 27.7046 7.08301 29.0625 8.43941Z" />
      </g>
      <defs>
        <clipPath id="clip0_6137_121201">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
