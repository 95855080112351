import { SVGProps } from 'react';
import { SVG, PathDesktop, PathMobile } from './styles';

function TwitterIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <SVG fill="none" xmlns="http://www.w3.org/2000/svg">
      <PathMobile
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M21.1084 2.03546C20.3321 2.37943 19.4982 2.61184 18.6216 2.71689C19.5261 2.17564 20.2029 1.32379 20.5255 0.320278C19.6757 0.825062 18.7456 1.18038 17.7756 1.37077C17.1234 0.674345 16.2594 0.212743 15.318 0.0576305C14.3765 -0.0974817 13.4101 0.0625739 12.5689 0.512948C11.7277 0.963322 11.0588 1.67882 10.6659 2.54835C10.273 3.41788 10.1782 4.39279 10.3962 5.32173C8.67417 5.23527 6.98961 4.7877 5.4518 4.00807C3.91399 3.22843 2.55729 2.13415 1.46976 0.796253C1.0979 1.4377 0.884087 2.18141 0.884087 2.97347C0.883672 3.6865 1.05926 4.3886 1.39528 5.01749C1.73129 5.64639 2.21734 6.18262 2.8103 6.57861C2.12262 6.55673 1.45012 6.37091 0.84876 6.03663V6.0924C0.848691 7.09246 1.19462 8.06173 1.82784 8.83577C2.46106 9.6098 3.34258 10.1409 4.32282 10.339C3.68489 10.5116 3.01606 10.5371 2.36686 10.4134C2.64343 11.2739 3.18215 12.0263 3.90761 12.5654C4.63308 13.1045 5.50896 13.4033 6.41265 13.4198C4.87859 14.6241 2.98404 15.2773 1.03376 15.2744C0.688287 15.2745 0.343108 15.2544 0 15.214C1.97964 16.4869 4.28409 17.1624 6.63762 17.1598C14.6046 17.1598 18.96 10.5612 18.96 4.83832C18.96 4.65239 18.9553 4.46461 18.947 4.27868C19.7941 3.66602 20.5254 2.90737 21.1065 2.03825L21.1084 2.03546V2.03546Z"
      />
      <PathDesktop
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M30.1543 2.89287C29.0511 3.38173 27.8659 3.71204 26.62 3.86134C27.9056 3.0921 28.8673 1.88142 29.3259 0.45519C28.1181 1.17261 26.7962 1.67759 25.4177 1.94819C24.4907 0.958403 23.2628 0.302358 21.9247 0.0819066C20.5867 -0.138545 19.2133 0.0889323 18.0177 0.72902C16.8222 1.36911 15.8714 2.386 15.313 3.6218C14.7547 4.85761 14.6199 6.2432 14.9297 7.56344C12.4823 7.44056 10.0882 6.80446 7.90259 5.69641C5.717 4.58836 3.78881 3.03314 2.24317 1.13166C1.71468 2.04332 1.41079 3.10031 1.41079 4.226C1.4102 5.23938 1.65976 6.23724 2.13731 7.13105C2.61487 8.02485 3.30566 8.78696 4.1484 9.34976C3.17105 9.31866 2.21526 9.05457 1.36059 8.57947V8.65875C1.36049 10.0801 1.85213 11.4576 2.75209 12.5577C3.65205 13.6578 4.9049 14.4126 6.29805 14.6942C5.39139 14.9395 4.44083 14.9757 3.51817 14.7999C3.91123 16.0228 4.67688 17.0922 5.70794 17.8584C6.739 18.6246 7.98384 19.0492 9.26819 19.0727C7.08793 20.7843 4.39532 21.7127 1.62351 21.7086C1.13252 21.7087 0.641934 21.6801 0.154297 21.6227C2.96784 23.4317 6.24301 24.3918 9.58793 24.3881C20.9109 24.3881 27.1009 15.0099 27.1009 6.8764C27.1009 6.61215 27.0943 6.34526 27.0824 6.08102C28.2865 5.21029 29.3258 4.13205 30.1517 2.89684L30.1543 2.89287Z"
      />
    </SVG>
  );
}

TwitterIcon.defaultProps = {
  width: 24,
};

export default TwitterIcon;
