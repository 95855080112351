import { SVGProps } from 'react';

export default function ArrowRightSmallIcon(
  props: SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ display: 'inline-block' }}
    >
      <path
        d="M14.0001 6.00006C13.9952 5.47396 13.7832 4.97096 13.4101 4.60007L9.12006 0.30007C8.9327 0.113819 8.67925 0.00927734 8.41506 0.00927734C8.15088 0.00927734 7.89742 0.113819 7.71006 0.30007C7.61633 0.393033 7.54194 0.503634 7.49117 0.625493C7.4404 0.747352 7.41426 0.878057 7.41426 1.01007C7.41426 1.14208 7.4404 1.27279 7.49117 1.39465C7.54194 1.5165 7.61633 1.62711 7.71006 1.72007L11.0001 5.00006H1.00006C0.734845 5.00006 0.480491 5.10542 0.292954 5.29296C0.105418 5.48049 6.10352e-05 5.73485 6.10352e-05 6.00006C6.10352e-05 6.26528 0.105418 6.51963 0.292954 6.70717C0.480491 6.89471 0.734845 7.00006 1.00006 7.00006H11.0001L7.71006 10.2901C7.52176 10.477 7.41544 10.7312 7.41451 10.9965C7.41357 11.2619 7.51808 11.5168 7.70506 11.7051C7.89204 11.8934 8.14616 11.9997 8.41153 12.0006C8.67689 12.0016 8.93176 11.897 9.12006 11.7101L13.4101 7.41006C13.7856 7.03671 13.9978 6.52964 14.0001 6.00006Z"
        fill="#5FA4A9"
      />
    </svg>
  );
}
