function CareIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448.25 448.25"
      width="24"
      height="24"
    >
      <path
        fill="currentColor"
        d="M352.992 23.661C341.84 13.165 327.568 8.125 313.184 8.125c-16.192 0-32.544 6.384-44.736 18.592l-5.232 5.232-5.232-5.248C245.792 14.509 229.44 8.125 213.248 8.125c-14.384 0-28.656 5.04-39.808 15.536-24.528 23.088-24.96 61.712-1.312 85.36l91.088 91.088 91.088-91.088c23.648-23.648 23.216-62.272-1.312-85.36zM442.4 246.493c-14-9.488-30.496-6.8-45.104 1.632s-98.48 80.144-98.48 80.144l-82.56.016c-3.84 0-8-4.32-8-8.144 0-4.496 3.92-8 8-8h50.512c17.68 0 37.472-11.52 37.472-32 0-21.76-19.792-32-37.472-32-30.672 0-36.64.128-36.64.128-12.288 0-24.752-.736-34.352-5.872-12.8-6.56-27.552-10.272-43.296-10.272-26.432 0-50.096 10.544-66.32 27.168L0 344.125l96 96 32-32h162.032a65.871 65.871 0 0043.744-16.624l109.712-123.072c6.704-5.952 6.32-16.896-1.088-21.936z"
      />
    </svg>
  );
}

export default CareIcon;
