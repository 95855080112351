function CartIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 3h3.636l2.437 12.115c.083.416.31.79.643 1.057.332.266.748.408 1.175.4h8.836c.427.008.843-.134 1.175-.4.333-.267.56-.64.643-1.057L22 7.524H6.545m3.637 13.571c0 .5-.407.905-.91.905a.907.907 0 01-.908-.905c0-.5.407-.904.909-.904s.909.405.909.904zm10 0c0 .5-.407.905-.91.905a.907.907 0 01-.908-.905c0-.5.407-.904.909-.904s.909.405.909.904z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CartIcon;
