import { SVGProps } from 'react';

export default function ShuffleIcon(
  props: SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6137_121203)">
        <path d="M48.7943 34.5462L41.9381 27.6899C41.747 27.4864 41.5169 27.3234 41.2616 27.2105C41.0063 27.0977 40.7309 27.0373 40.4517 27.033C40.1726 27.0286 39.8955 27.0804 39.6367 27.1853C39.378 27.2902 39.143 27.446 38.9457 27.6434C38.7484 27.8409 38.5928 28.076 38.4881 28.3348C38.3834 28.5936 38.3318 28.8708 38.3363 29.1499C38.3409 29.429 38.4014 29.7044 38.5145 29.9596C38.6275 30.2149 38.7907 30.4448 38.9943 30.6357L43.7651 35.4149C41.1717 35.4208 38.6032 34.9077 36.211 33.9059C33.8189 32.9041 31.6513 31.4338 29.836 29.5816C29.6344 29.3959 29.3976 29.2527 29.1396 29.1603C28.8816 29.068 28.6076 29.0284 28.334 29.0439C28.0604 29.0595 27.7928 29.1298 27.5469 29.2508C27.301 29.3718 27.0819 29.541 26.9027 29.7482L26.8756 29.7899C26.5253 30.1974 26.346 30.7244 26.3751 31.2609C26.4043 31.7974 26.6395 32.3019 27.0318 32.6691C29.2189 34.8809 31.827 36.6322 34.702 37.8194C37.5771 39.0067 40.6609 39.6059 43.7714 39.5816L38.9943 44.3587C38.7766 44.5459 38.6 44.7761 38.4755 45.0348C38.351 45.2936 38.2814 45.5752 38.2709 45.8622C38.2604 46.1491 38.3094 46.4351 38.4147 46.7022C38.52 46.9693 38.6794 47.2118 38.8828 47.4144C39.0863 47.617 39.3294 47.7753 39.597 47.8795C39.8646 47.9837 40.1508 48.0314 40.4376 48.0197C40.7245 48.008 41.0059 47.9372 41.2641 47.8116C41.5223 47.686 41.7517 47.5084 41.9381 47.2899L48.7943 40.4337C49.5741 39.6525 50.0122 38.5937 50.0122 37.4899C50.0122 36.3861 49.5741 35.3273 48.7943 34.5462V34.5462Z" />
        <path d="M43.7647 14.5824L38.9939 19.3532C38.9533 19.4081 38.9157 19.4651 38.8814 19.5241C38.5452 19.9339 38.3794 20.4574 38.4182 20.9861C38.4571 21.5148 38.6976 22.0083 39.0901 22.3647C39.4826 22.7211 39.997 22.913 40.527 22.9008C41.057 22.8885 41.562 22.6731 41.9376 22.2991L45.4918 18.7491L48.796 15.447C49.3546 14.884 49.7409 14.1732 49.9096 13.3982C50.0782 12.6232 50.0222 11.8162 49.7481 11.072C49.5456 10.5023 49.2198 9.98437 48.7939 9.5553L41.9376 2.70739C41.7474 2.50074 41.5174 2.33468 41.2614 2.21919C41.0054 2.10371 40.7287 2.04118 40.4479 2.03536C40.1671 2.02954 39.888 2.08056 39.6275 2.18534C39.3669 2.29013 39.1302 2.44651 38.9316 2.64511C38.733 2.8437 38.5766 3.08039 38.4719 3.34097C38.3671 3.60154 38.3161 3.88061 38.3219 4.1614C38.3277 4.44219 38.3902 4.71891 38.5057 4.97492C38.6212 5.23092 38.7872 5.46094 38.9939 5.65114L43.771 10.4261C33.4939 10.4511 27.8606 15.997 22.7523 21.8303C18.0606 16.5345 12.5002 11.2491 3.09811 10.5116L2.09603 10.4157C1.54349 10.4157 1.01359 10.6352 0.622889 11.0259C0.232188 11.4166 0.0126953 11.9465 0.0126953 12.4991C0.0233623 13.0482 0.24628 13.572 0.634692 13.9604C1.0231 14.3488 1.54683 14.5717 2.09603 14.5824C2.24603 14.5824 2.69394 14.6511 2.69394 14.6511C10.8106 15.2657 15.4689 19.8366 20.0002 24.9991C15.496 30.1366 10.8689 34.7907 2.81894 35.3324L1.98561 35.3761C1.43722 35.4104 0.924899 35.6611 0.561353 36.0731C0.197806 36.4851 0.0128132 37.0246 0.0470703 37.573C0.0813274 38.1214 0.332028 38.6337 0.744022 38.9973C1.15602 39.3608 1.69555 39.5458 2.24394 39.5115C2.56894 39.497 3.09811 39.4845 3.35852 39.4636C13.8168 38.6574 19.4898 31.9636 24.5481 26.1116C29.6564 20.2074 34.4814 14.6199 43.7647 14.5824Z" />
      </g>
      <defs>
        <clipPath id="clip0_6137_121203">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
