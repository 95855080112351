import { SVGProps } from 'react';

function StarOutlineIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={16} cy={16} r={15} stroke="currentColor" strokeWidth={2} />
      <path
        d="M16 9l1.572 4.837h5.085l-4.114 2.99 1.572 4.836L16 18.673l-4.114 2.99 1.571-4.837-4.114-2.99h5.085L16 9z"
        fill="currentColor"
      />
    </svg>
  );
}

export default StarOutlineIcon;
