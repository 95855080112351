import { SVGProps } from 'react';

function WarningIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 102 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51 6.59424C42.174 6.59424 33.5462 9.21728 26.2077 14.1317C18.8692 19.046 13.1495 26.031 9.7719 34.2033C6.39434 42.3757 5.51061 51.3682 7.23248 60.0439C8.95434 68.7196 13.2045 76.6887 19.4454 82.9435C25.6863 89.1984 33.6377 93.4579 42.2941 95.1836C50.9505 96.9093 59.9231 96.0237 68.0773 92.6386C76.2314 89.2535 83.2009 83.5211 88.1044 76.1662C93.0078 68.8113 95.625 60.1643 95.625 51.3186C95.625 39.457 90.9235 28.0812 82.5547 19.6937C74.1859 11.3063 62.8353 6.59424 51 6.59424V6.59424ZM51 89.6538C43.4349 89.6538 36.0396 87.4055 29.7495 83.1932C23.4593 78.9808 18.5567 72.9937 15.6616 65.9889C12.7666 58.984 12.0091 51.2761 13.485 43.8398C14.9609 36.4035 18.6038 29.5728 23.9532 24.2116C29.3025 18.8503 36.118 15.1992 43.5378 13.72C50.9576 12.2409 58.6484 13 65.6377 15.9015C72.6269 18.803 78.6008 23.7165 82.8037 30.0207C87.0067 36.3249 89.25 43.7366 89.25 51.3186C89.25 61.4858 85.2201 71.2365 78.0469 78.4257C70.8736 85.6149 61.1446 89.6538 51 89.6538Z"
        fill="#525255"
      />
      <path
        d="M47.8125 25.7617H54.1875V60.9023H47.8125V25.7617Z"
        fill="#525255"
      />
      <path
        d="M51 70.4863C50.0544 70.4863 49.13 70.7674 48.3437 71.2939C47.5574 71.8205 46.9446 72.5688 46.5827 73.4444C46.2208 74.3201 46.1261 75.2835 46.3106 76.2131C46.4951 77.1426 46.9505 77.9964 47.6191 78.6666C48.2878 79.3368 49.1398 79.7931 50.0672 79.978C50.9947 80.1629 51.956 80.068 52.8297 79.7054C53.7034 79.3427 54.4501 78.7285 54.9755 77.9405C55.5008 77.1524 55.7813 76.226 55.7813 75.2782C55.7813 74.0073 55.2775 72.7885 54.3809 71.8898C53.4842 70.9912 52.2681 70.4863 51 70.4863Z"
        fill="#525255"
      />
    </svg>
  );
}

WarningIcon.defaultProps = {
  width: 102,
  height: 103,
};

export default WarningIcon;
