import { SVGProps } from 'react';

function ToolsOutlineIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.685 12.77a.48.48 0 00-.533.1l-1.421 1.42-2.592-.458-.459-2.592 1.421-1.42A.48.48 0 0018.74 9a4.038 4.038 0 00-3.615 5.373L9.55 19.95a1.787 1.787 0 102.527 2.528l5.601-5.602a4.038 4.038 0 005.292-3.643.481.481 0 00-.286-.462zm-11.003 8.64a.754.754 0 11-1.065-1.066.754.754 0 011.065 1.066z"
        fill="#000"
      />
      <path
        d="M31.06 16c0 8.287-6.695 15-14.947 15C7.86 31 1.166 24.287 1.166 16c0-8.287 6.695-15 14.947-15C24.365 1 31.06 7.713 31.06 16z"
        stroke="#000"
        strokeWidth={2}
      />
    </svg>
  );
}

export default ToolsOutlineIcon;
