function ThumbSubIcon(): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.56389 6.2003C3.81662 6.2003 3.98055 6.36439 3.98055 6.61737V15.0829C3.98055 15.3358 3.81662 15.4999 3.56389 15.4999H1.08268C0.829949 15.4999 0.666016 15.3358 0.666016 15.0829V6.61737C0.666016 6.36439 0.829949 6.2003 1.08268 6.2003H3.56389ZM10.8695 0.688352C10.9053 0.720436 10.938 0.755855 10.967 0.7941L11.0077 0.853522L11.057 0.93356C11.4228 1.52651 11.5463 2.23744 11.4018 2.91893L10.8904 5.33168H15.7822C16.635 5.33168 17.3327 6.01592 17.3327 6.85221C17.3327 7.26076 17.2647 7.66647 17.1316 8.05274L14.8869 14.565C14.6543 15.1123 14.1039 15.4925 13.4604 15.4925L7.25445 15.4979C5.96997 15.5011 4.92779 14.4611 4.92667 13.1771L4.92851 6.83698C4.92863 6.42697 5.07988 6.0314 5.35333 5.7259L9.80986 0.747034C10.0862 0.438201 10.5607 0.411938 10.8695 0.688352Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ThumbSubIcon;
