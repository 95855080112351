import { SVGProps } from 'react';

function DeleteIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 125 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M71.875 25C71.875 22.5136 70.8873 20.129 69.1291 18.3709C67.371 16.6127 64.9864 15.625 62.5 15.625C60.0136 15.625 57.629 16.6127 55.8709 18.3709C54.1127 20.129 53.125 22.5136 53.125 25H46.875C46.875 20.856 48.5212 16.8817 51.4515 13.9515C54.3817 11.0212 58.356 9.375 62.5 9.375C66.644 9.375 70.6183 11.0212 73.5485 13.9515C76.4788 16.8817 78.125 20.856 78.125 25H106.25C107.079 25 107.874 25.3292 108.46 25.9153C109.046 26.5013 109.375 27.2962 109.375 28.125C109.375 28.9538 109.046 29.7487 108.46 30.3347C107.874 30.9208 107.079 31.25 106.25 31.25H102.787L94.6875 101.437C94.3348 104.484 92.8747 107.294 90.5849 109.334C88.2951 111.374 85.3353 112.5 82.2688 112.5H42.7312C39.6647 112.5 36.7049 111.374 34.4151 109.334C32.1253 107.294 30.6652 104.484 30.3125 101.437L22.2062 31.25H18.75C18.0186 31.2502 17.3103 30.9939 16.7484 30.5257C16.1865 30.0575 15.8067 29.407 15.675 28.6875L15.625 28.125C15.625 27.2962 15.9542 26.5013 16.5403 25.9153C17.1263 25.3292 17.9212 25 18.75 25H71.875ZM96.4875 31.25H28.5062L36.5187 100.719C36.6951 102.242 37.4257 103.648 38.5712 104.668C39.7168 105.688 41.1974 106.251 42.7312 106.25H82.2688C83.8015 106.249 85.2806 105.686 86.4249 104.666C87.5691 103.646 88.2987 102.241 88.475 100.719L96.4875 31.25ZM53.125 46.875C54.6563 46.875 55.9375 47.8437 56.2 49.1187L56.25 49.6125V87.8937C56.25 89.4 54.85 90.625 53.125 90.625C51.5937 90.625 50.3125 89.6562 50.05 88.3812L50 87.8875V49.6187C50 48.1062 51.4 46.8812 53.125 46.8812V46.875ZM71.875 46.875C73.4063 46.875 74.6875 47.8437 74.95 49.1187L75 49.6125V87.8937C75 89.4 73.6 90.625 71.875 90.625C70.3437 90.625 69.0625 89.6562 68.8 88.3812L68.75 87.8875V49.6187C68.75 48.1062 70.15 46.8812 71.875 46.8812V46.875Z"
        fill="#525255"
      />
    </svg>
  );
}

DeleteIcon.defaultProps = {
  width: 125,
  height: 125,
};

export default DeleteIcon;
