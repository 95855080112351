import { SVGProps } from 'react';

export default function ShieldCheckIcon(
  props: SVGProps<SVGSVGElement>,
): JSX.Element {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      style={{ display: 'inline-block' }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.74212 0.892011L5.13171 0.0215945C5.04624 -0.00687264 4.95384 -0.00687264 4.86837 0.0215945L2.25796 0.892011C1.84292 1.02988 1.48188 1.29504 1.22614 1.64981C0.970405 2.00458 0.832973 2.43092 0.833375 2.86826V5.00035C0.833375 8.1516 4.66671 9.89201 4.83087 9.96451C4.88413 9.98817 4.94176 10.0004 5.00004 10.0004C5.05832 10.0004 5.11595 9.98817 5.16921 9.96451C5.33337 9.89201 9.16671 8.1516 9.16671 5.00035V2.86826C9.16711 2.43092 9.02968 2.00458 8.77394 1.64981C8.5182 1.29504 8.15716 1.02988 7.74212 0.892011ZM6.96587 4.0491L5.18587 5.8291C5.11303 5.90242 5.02635 5.96055 4.93086 6.00011C4.83538 6.03966 4.73298 6.05985 4.62962 6.05951H4.61587C4.51041 6.0579 4.40637 6.03491 4.31005 5.99194C4.21372 5.94897 4.12711 5.88691 4.05546 5.80951L3.09462 4.80951C3.05301 4.77091 3.01973 4.7242 2.99681 4.67227C2.9739 4.62034 2.96184 4.56427 2.96137 4.50751C2.9609 4.45075 2.97203 4.39449 2.99408 4.34219C3.01614 4.28988 3.04864 4.24263 3.08961 4.20334C3.13058 4.16405 3.17914 4.13355 3.23232 4.1137C3.2855 4.09385 3.34218 4.08508 3.39887 4.08792C3.45556 4.09076 3.51108 4.10515 3.56201 4.13022C3.61293 4.15528 3.65821 4.19049 3.69504 4.23368L4.63004 5.20868L6.37504 3.45868C6.45363 3.38278 6.55888 3.34078 6.66812 3.34173C6.77737 3.34268 6.88188 3.3865 6.95913 3.46375C7.03639 3.54101 7.08021 3.64551 7.08116 3.75476C7.0821 3.86401 7.04011 3.96926 6.96421 4.04785L6.96587 4.0491Z"
        fill="#C5C7D3"
      />
    </svg>
  );
}
