function Village(): JSX.Element {
  return (
    <svg
      width="21px"
      height="21px"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.854 21.77h-7.012a.92.92 0 01-.917-.916V16.5c0-1.1-.87-1.97-1.97-1.97s-1.972.87-1.972 1.97v4.354a.92.92 0 01-.916.917H1.146a.92.92 0 01-.917-.917V9.121a2.56 2.56 0 011.054-2.063L10.496.413a.894.894 0 011.054 0l9.213 6.645a2.56 2.56 0 011.054 2.063V20.9a.989.989 0 01-.963.87zm-6.096-1.787h5.225V9.121c0-.23-.137-.458-.32-.596L11 2.245 2.338 8.48c-.184.138-.321.367-.321.596v10.863h5.225V16.5A3.775 3.775 0 0111 12.742a3.775 3.775 0 013.758 3.758v3.483z"
        fill="#D1C3B8"
      />
    </svg>
  );
}

export default Village;
