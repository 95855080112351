import { SVGProps } from 'react';
import { PathDesktop, PathMobile, SVG } from './styles';

function FacebookSymbolIcon(
  props: Omit<SVGProps<SVGSVGElement>, 'css'>,
): JSX.Element {
  return (
    <>
      <SVG {...props} xmlns="http://www.w3.org/2000/svg">
        <PathMobile
          fill="currentColor"
          d="M0 9.0498C0 13.4861 3.22197 17.175 7.4359 17.9231V11.4784H5.20513V8.99998H7.4359V7.01683C7.4359 4.78606 8.87326 3.54724 10.9062 3.54724C11.5502 3.54724 12.2447 3.64613 12.8886 3.74503V6.02562H11.7487C10.6579 6.02562 10.4103 6.57067 10.4103 7.26519V8.99998H12.7897L12.3934 11.4784H10.4103V17.9231C14.6242 17.175 17.8462 13.4868 17.8462 9.0498C17.8462 4.1146 13.8308 0.0769043 8.92308 0.0769043C4.01538 0.0769043 0 4.1146 0 9.0498Z"
        />
        <PathDesktop
          fill="currentColor"
          d="M0 15.9295C0 23.387 5.41625 29.5882 12.5 30.8457V20.012H8.75V15.8457H12.5V12.512C12.5 8.76195 14.9163 6.67945 18.3337 6.67945C19.4163 6.67945 20.5837 6.8457 21.6663 7.01195V10.8457H19.75C17.9163 10.8457 17.5 11.762 17.5 12.9295V15.8457H21.5L20.8337 20.012H17.5V30.8457C24.5837 29.5882 30 23.3882 30 15.9295C30 7.6332 23.25 0.845703 15 0.845703C6.75 0.845703 0 7.6332 0 15.9295Z"
        />
      </SVG>
    </>
  );
}

FacebookSymbolIcon.defaultProps = {
  width: 24,
};

export default FacebookSymbolIcon;
