function PlayOutline(): JSX.Element {
  return (
    <svg
      width="98"
      height="98"
      viewBox="0 0 98 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <g filter="url(#filter1_b)">
          <circle cx="49" cy="49" r="45" fill="white" fillOpacity="0.5" />
          <circle
            cx="49"
            cy="49"
            r="44.1438"
            stroke="#5fa3a9"
            strokeWidth="1.71233"
          />
        </g>
        <path
          d="M74.4794 48.4068C74.9361 48.6705 74.9361 49.3296 74.4794 49.5932L36.774 71.3625C36.3173 71.6261 35.7466 71.2965 35.7466 70.7693L35.7466 27.2307C35.7466 26.7035 36.3173 26.3739 36.774 26.6376L74.4794 48.4068Z"
          fill="#5fa3a9"
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.875"
          y="0.875"
          width="96.25"
          height="96.25"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1.5625" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_b"
          x="2.75"
          y="2.75"
          width="92.5"
          height="92.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="0.625" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default PlayOutline;
