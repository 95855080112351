import { SVGProps } from 'react';

export default function LabelIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6137_121202)">
        <path d="M42.6191 9.48967L26.0128 0.262586C25.7031 0.0903771 25.3547 0 25.0003 0C24.646 0 24.2975 0.0903771 23.9878 0.262586L7.38158 9.48967C6.40742 10.0309 5.59569 10.8228 5.03048 11.7832C4.46528 12.7437 4.16715 13.8378 4.16699 14.9522V39.5834C4.1703 42.3451 5.26883 44.9927 7.22162 46.9455C9.1744 48.8983 11.822 49.9968 14.5837 50.0001H35.417C38.1787 49.9968 40.8263 48.8983 42.779 46.9455C44.7318 44.9927 45.8304 42.3451 45.8337 39.5834V14.9522C45.8335 13.8378 45.5354 12.7437 44.9702 11.7832C44.405 10.8228 43.5932 10.0309 42.6191 9.48967V9.48967ZM41.667 39.5834C41.667 41.241 41.0085 42.8307 39.8364 44.0028C38.6643 45.1749 37.0746 45.8334 35.417 45.8334H14.5837C12.9261 45.8334 11.3363 45.1749 10.1642 44.0028C8.99214 42.8307 8.33366 41.241 8.33366 39.5834V14.9522C8.33273 14.5795 8.43179 14.2134 8.6205 13.892C8.80921 13.5707 9.08067 13.3058 9.40658 13.1251L25.0003 4.46675L40.5962 13.1251C40.9217 13.3061 41.1927 13.5711 41.3811 13.8924C41.5694 14.2138 41.6681 14.5797 41.667 14.9522V39.5834Z" />
        <path d="M25 17.709C26.7259 17.709 28.125 16.3099 28.125 14.584C28.125 12.8581 26.7259 11.459 25 11.459C23.2741 11.459 21.875 12.8581 21.875 14.584C21.875 16.3099 23.2741 17.709 25 17.709Z" />
      </g>
      <defs>
        <clipPath id="clip0_6137_121202">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
